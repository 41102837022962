<template>
    <ModalContent name="checkPackagesModal" ref="modal" width="42%" v-bind="$attrs" v-on="$listeners" :showFooter="false">
        <template v-if="checkData" #title> {{ checkData.name }} Packages </template>
        <template v-if="checkData" #content>
            <div class="h-96 overflow-y-auto scroll-bar">
                <div v-if="isLoading" class="flex items-center justify-center p-5 w-full">
                    <Loader />
                </div>
                <ListView v-else :listItems="packages" :showSearch="true" @handleFilterResult="$emit('handleFilterResult', $event)">
                    <template #item="{ item }">
                        <router-link
                            :to="{
                                name: 'Screening Package View',
                                params: { packageName: item.id, action: 'edit' },
                            }"
                            class="text-primary w-full"
                            >
                            {{ item.name }}
                        </router-link>
                    </template>
                </ListView>
            </div>
        </template>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view/index.vue";
import Loader from "@shared/loader";
import axios from "@/axios";
export default {
    name: "check-packages-modal",
    components: {
        ModalContent,
        ListView,
        Loader,
    },
    props: {
        checkData: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        packages: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        async editPackage(package_id) {
            if(package_id == null){
                return
            }
            try {
                let payload = { lock_status: true }
                let url = `/screening-package/locked/${package_id}`;
                await axios.post(url, payload);
                this.$router.push({
                    name: "Screening Package View",
                    params: { packageName: package_id, action: "edit" },
                });
                return true;
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to lock this package");
                return false; // Return false when there's an error
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
